<template>
    <div class="productEntry">
        <div class="searchArea flex_ac">
            <div class="input">
                <el-input placeholder="请输入手机号码"></el-input>
            </div>
            <el-button class="search" type="primary">搜索</el-button>
        </div>
        <div class="descriptionArea">
          <div class="desTitle">产品描述（对客展示）</div>
          <div class="textArea">
            <el-input type="textarea" resize="none"></el-input>
          </div>
        </div>
        <div class="productArea flex">
            <div class="area">
                <div>
                    <el-form label-position="top" :model="productForm" ref="userForm" :rules="rules">
                        <el-form-item label="套餐名" prop="name">
                            <div class="input"><el-input v-model="productForm.name"></el-input></div>
                        </el-form-item>
                        <el-form-item label="产品价格" prop="price">
                            <div class="input"><el-input type="price" v-model="productForm.price"></el-input></div>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="setStatus">
                    <div class="statusName">是否有效</div>
                    <div class="setList flex">
                        <div class="active">有效</div>
                        <div>失效</div>
                    </div>
                </div>
            </div>
            <div class="area">
                <div class="remark">
                    <div class="remarkName">产品备注（供应商等信息，仅对员工展示）</div>
                    <div class="remarkContent">
                        Xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx……….
                    </div>
                    <el-button class="entry" type="primary">录入</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'productEntry',
  data() {
    return {
      productForm: {
        name: '',
        price: ''
      },
      rules: {
        name: [{ message: '请输入套餐名', trigger: 'blur', required: true }],
        price: [{ required: true, message: '请输入价格', trigger: 'blur' }]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.productEntry {
  padding: 20px;
  border: 1px solid #e3e3e3;
  .searchArea {
    justify-content: flex-end;
    .search {
      width: 80px;
      height: 38px;
      margin-left: 10px;
    }
  }
  .descriptionArea {
    .desTitle {
        font-weight: bold;
    }
    .textArea {
        margin-top: 16px;
    }
  }
  .productArea {
    margin-top: 16px;
    .area {
        flex: 1;
        .input {
          width: 300px;
        }
        .setStatus {
            margin-top: 20px;
            .setList {
                margin-top: 10px;
                div {
                    font-size: 14px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 56px;
                    height: 29px;
                    border: 1px solid #e3e3e3;
                    border-radius: 3px;
                   cursor: pointer;
                   &.active {
                      border: unset;
                      background-color: #409EFF;
                      color: #fff;
                   }
                    &:last-child {
                        margin-left: 10px;
                    }
                }
            }
        }
        .remark {
            line-height: 40px;
            padding-bottom: 10px;
            font-size: 14px;
            color: #606266;
            .remarkContent {
                min-height: 160px;
            }
        }
        .entry {
          width: 280px;
          height: 50px;
          margin-top: 30px;
        }
    }
  }
}

</style>
